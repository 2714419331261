<template>
    <div class="section-two section__scroll-margin" id="fachgebiete">
        <h2>Meine Fachgebiete</h2>
        <div class="block block--tablet-col">
            <card
                class="section-two__card"
                title="Paar- und Sexualtherapie"
                content="In einer Beziehung kann es trotz Liebe und Nähe leicht zu Missverständnissen und Konflikten kommen."
                icon="icon-couple-therapy.png"
                router="/sexual-und-paartherapie"
            />
            <card
                class="section-two__card"
                title="Supervision"
                content="Supervision ist eine Form der Beratung für Mitarbeiter/-innen. Ob Konflikte im Team, Mobbing oder Stress..."
                icon="icon-supervision.png"
                router="/supervision"
            />
        </div>
    </div>
</template>
<script>
import Card from '../components/Card.vue';

export default {
    name: 'HomeSectionTwo',
    components: {
        Card
    }
}
</script>
<style lang="css" scoped>
    @media (max-width: 1000px) {
        .section-two__card {
            width: 37%;
            min-width: unset;
        }
    }
    @media (max-width: 750px) {
        .section-two__card {
            width: unset;
            max-width: unset;
        }
        .section-two .block {
            align-items: center;
        }
    }
</style>