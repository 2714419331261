<template>
    <div class="scroll-top">
        <button class="btn btn--naked btn--scroll-top btn--hover-style" @click="scrollTop">
            nach oben 
            <ios-arrow-round-up-icon w="40" h="40" />
        </button>
    </div>
</template>
<script>
export default {
    name: 'ScrollTop',
    methods: {
        scrollTop () {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }
}
</script>
<style lang="css" scoped>
    .scroll-top {
        justify-content: right;
        display: flex;
        margin-top: 20px;
    }
    .btn--scroll-top {
        display: flex;
        align-items: center;
    }
</style>