import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import SexualPaartherapie from "@/views/SexualPaartherapie.vue";
import Supervision from "@/views/Supervision.vue";
import UeberMich from "@/views/UeberMich.vue";
import Praxis from "@/views/Praxis.vue";
import Impressum from "@/views/Impressum.vue";
import PageNotFound from "@/views/PageNotFound.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/sexual-und-paartherapie",
        name: "SexualPaartherapie",
        component: SexualPaartherapie,
    },
    {
        path: "/supervision",
        name: "Supervision",
        component: Supervision,
    },
    {
        path: "/ueber-mich",
        name: "UeberMich",
        component: UeberMich,
    },
    {
        path: "/praxis",
        name: "Praxis",
        component: Praxis,
    },
    {
        path: "/impressum",
        name: "Impressum",
        component: Impressum,
    },
    {
        path: "/:pathMatch(.*)",
        component: PageNotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll smooth to top
        if (!window.location.hash) {
            window.scrollTo({ top: 0 });
        }
    },
});

export default router;
