import { createStore } from "vuex";

// Create a new store instance.
const store = createStore({
    state() {
        return {
            showCookieBanner: true,
            consentGiven: false,
            showFlyout: false
        };
    },
    mutations: {
        setShowCookieBanner(state, show) {
            state.showCookieBanner = show;
        },
        setConsentGiven(state, consentGiven) {
            state.consentGiven = consentGiven;
        },
        setShowFlyout(state, show) {
            state.showFlyout = show;
        },
    },
});

export default store;