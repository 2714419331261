<template>
    <div class="card">
        <div v-if="icon" class="card__icon--background">
            <img class="card__icon" :src="require('@/assets/images/' + icon)" />
        </div>
        <h3 class="card__title" v-html="title"></h3>
        <p v-html="content"></p>
        <router-link v-if="router" class="card__router" :to="router">
            <button class="btn btn--primary">
                Mehr erfahren
            </button>
        </router-link>
        </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: false,
            default: null
        },
        router: {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>

<style lang="css">
    .card {
        background-color: var(--background-card);
        border-radius: 20px;
        padding: 30px;
        min-width: 315px;
        max-width: 315px;
        border: 1px solid var(--border-card);
    }
    .card__icon {
        width: 80px;
        height: 80px;
        margin: auto;
        filter: opacity(0.45);
    }
    .card__icon--background {
        background-color: var(--action-secondary);
        width: 90px;
        height: 90px;
        border-radius: 45px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .card__title {
        text-align: center;
        align-content: center;
        display: grid;
        height: 40px;
        margin-bottom: 35px;
    }
    .card__router {
        display: flex;
        justify-content: center;
        text-decoration: none;
    }
    .card--unset-fixed-width {
        max-width: unset;
        min-width: unset;
        width: -webkit-fill-available;
        width: -moz-available;
        width: fill-available;
    }
</style>