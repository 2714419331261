<template>
    <div class="section-three section__scroll-margin block block--mobile-col" id="ueber-mich">
        <img src="@/assets/images/banner-about-me.jpg" class="img section-three__img" />
        <div class="block__content">
            <h2>Über mich</h2>
            <p>
                <i>
                    "Ich bin ein interessierter, offener und direkter Mensch, der es mag, den Dingen auf den Grund zu gehen.“
                </i>
            </p>
            <router-link to="/ueber-mich">
                <button class="btn btn--secondary">Mehr zu meiner Person</button>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HomeSectionOne'
}
</script>
<style lang="css" scoped>
    @media (max-width: 750px) {
        .section-three__img {
            width: 30%;
        }
    }
    @media (max-width: 500px) {
        .section-three__img {
            width: 120%;
            height: 400px;
            margin-left: -10%;
            border-radius: 0;
        }
    }
</style>