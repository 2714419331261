<template>
    <div class="home">
        <!-- home -->
        <home-section-one />

        <!-- fachgebiete -->
        <home-section-two />

        <!-- ueber mich -->
        <home-section-three />
        
        <!-- praxis -->
        <home-section-four />

        <!-- terminanfrage -->
        <home-section-five />

        <scroll-top />
    </div>
</template>
<script>
import HomeSectionOne from './HomeSectionOne.vue';
import HomeSectionTwo from './HomeSectionTwo.vue';
import HomeSectionThree from './HomeSectionThree.vue';
import HomeSectionFour from './HomeSectionFour.vue';
import HomeSectionFive from './HomeSectionFive.vue';
import ScrollTop from '../components/ScrollTop.vue';

export default {
    name: 'Home',
    components: {
        HomeSectionOne,
        HomeSectionTwo,
        HomeSectionThree,
        HomeSectionFour,
        HomeSectionFive,
        ScrollTop
    }
}
</script>
<style lang="css" scoped>
    
</style>