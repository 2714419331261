<template>
    <div class="section-five section__scroll-margin" id="terminanfrage">
        <h2>Terminanfrage</h2>
        <form v-if="!sendSuccess" ref="form" @submit.prevent="submitForm">
            <div class="block block--mobile-col block--no-margin-bottom section-five--no-gap">
                <div class="section-five__personal-data">
                    <h3>Ihre Daten</h3>
                    <label for="name" class="label">Vor- und Nachname *</label>
                    <input
                        class="input"
                        name="name"
                        type="text"
                        placeholder="Max Musterfrau"
                        v-model="form.name"
                        :disabled="isSending"
                        required
                    />
                    <label for="email" class="label">E-Mail-Adresse *</label>
                    <input
                        class="input"
                        name="email"
                        type="email"
                        placeholder="max.musterfrau@mail.ch"
                        v-model="form.mailAdress"
                        :disabled="isSending"
                        required
                    />
                    <label for="phoneNumber" class="label">Telefonnummer *</label>
                    <input
                        class="input"
                        name="phoneNumber"
                        type="tel"
                        placeholder="0041 1234567"
                        v-model="form.phoneNumber"
                        :disabled="isSending"
                        required
                    />
                </div>
                <div class="section-five__message">
                    <h3>Ihr Anliegen</h3>
                    <label for="subject" class="label">Betreff *</label>
                    <select 
                        class="input"
                        name="subject"
                        required
                        v-model="form.subject"
                        :disabled="isSending"
                    >
                        <option disabled selected="selected" value> -- Wählen Sie einen Betreff -- </option>
                        <option value="Paartherapie">Paartherapie</option>
                        <option value="Sexualtherapie">Sexualtherapie</option>
                        <option value="Paar- und Sexualtherapie ">Paar- und Sexualtherapie</option>
                        <option value="Supervision">Supervision</option>
                        <option value="Sonstiges">Sonstiges</option>
                    </select>

                    <label for="message" class="label">Anschreiben *</label>
                    <textarea
                        class="textarea"
                        name="message"
                        type="text"
                        placeholder="Hallo, mein Name lautet Max Musterfrau. Ich würde gerne ein Erstgespräch zum Thema..."
                        v-model="form.message"
                        :disabled="isSending"
                        required
                    />
                </div>
            </div>
            <p v-if="sendError !== ''" class="error-text" v-html="sendError"></p>
            <button 
                class="btn btn--primary btn--hover-style" 
                type="submit"
                :disabled="isSending"
                :class="{'btn--disabled': isSending}"
            >
                Absenden
            </button>
        </form>
        <p v-else>Danke für Ihre Anfrage. Ich melde mich demnächst bei Ihnen.</p>
    </div>
</template>
<script>
// Docs and Admin Panel links
// https://www.emailjs.com/docs/
// https://dashboard.emailjs.com/admin/templates/m6ex35e
import emailjs from '@emailjs/browser';

export default {
    name: 'HomeSectionFive',
    data () {
        return {
            form: {
                name: null,
                email: null,
                phoneNumber: null,
                subject: null,
                message: null
            },
            sendSuccess: false,
            sendError: '',
            isSending: false
        }
    },
    methods: {
        submitForm () {
            this.isSending = true;
            // serviceId, formId, publicKey
            emailjs.sendForm('service_9zr8bib', 'fa10c464-8566-11ed-a1eb', this.$refs.form, 'yneqOoAyGIlMPf1WQ')
                .then(() => {
                    this.form.name = null;
                    this.form.email = null;
                    this.form.phoneNumber = null;
                    this.form.subject = null;
                    this.form.message = null;
                    this.sendSuccess = true;
                    this.sendError = '';
                    this.isSending = false;
                }, (error) => {
                    console.error(error);
                    this.sendError = 'Die Anfrage konnte nicht abgeschickt werden.<br /><br />Bitte versuchen Sie es erneut oder senden Sie direkt eine Mail an <a href="mailto:info@praxis-bettinathaler.ch">info@praxis-bettinathaler.ch</a> oder rufen Sie an.';
                    this.isSending = false;
                });
        }
    }
}
</script>
<style lang="css" scoped>
    .section-four__google-map {
        border: 0;
        border-radius: 20px;
        filter: grayscale(1);
    }
    .section-five__message, .section-five__personal-data {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    @media (max-width: 500px) {
        .section-five__message, .section-five__personal-data {
            width: 100%;
        }
        .section-five--no-gap {
            gap: 0;
        }
    }
</style>