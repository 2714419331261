<template>
    <div class="page-not-found">
        <h1>404</h1>
        <h2>Seite konnte nicht gefunden werden</h2>
        <p>zurück zur <router-link to="/">Startseite</router-link></p>
    </div>
</template>
<script>
export default {
    name: 'PageNotFound'
}
</script>
<style lang="css" scoped>
    .page-not-found {
        margin-top: 100px;
    }
</style>