<template>
    <div class="section-one block block--tablet-rev-col">
        <div class="block__content">
            <span>Willkommen auf meiner Praxisseite</span>
            <h1>Ich freue mich auf Ihre Anfrage</h1>
            <p>
                Machen Sie eine unverbindliche
                Terminanfrage. Ich melde mich
                bei Ihnen.
            </p>
            <div class="section-one__button-container">
                <router-link to="/#terminanfrage">
                    <button class="btn btn--primary" @click="navigateToId('#terminanfrage')">Terminanfrage</button>
                </router-link>
                <router-link to="/#ueber-mich">
                    <button class="btn btn--secondary" @click="navigateToId('#ueber-mich')">Über mich</button>
                </router-link>
            </div>
        </div>
        <img src="@/assets/images/banner-top.jpg" class="img section-one__img" />
    </div>
</template>
<script>
export default {
    name: 'HomeSectionOne',
    methods: {
        navigateToId (id) {
            // wait for navigation - with setTimeout function in is queue
            document.querySelector(id).scrollIntoView({ behavior: 'smooth'  });
        }
    }
}
</script>
<style lang="css" scoped>
    .section-one__button-container {
        display: flex;
        gap: 20px;
    }
    .section-one__img {
        max-width: 480px;
        width: 50%;
    }
    @media (max-width: 850px) {
        .section-one__img {
            width: 40%;
        }
    }
    @media (max-width: 750px) {
        .section-one__img {
            width: 130%;
            height: 400px; 
            margin-left: -15%;
            border-radius: 0;
            max-width: unset;
        }
    }
    @media (max-width: 500px) {
        .section-one__img {
            width: 120%;
            margin-left: -10%;
        }
    }
</style>