<template>
    <div class="spt subpage">
        <h1>Sexual - und Paartherapie</h1>
        <p class="subpage__introduction">
            In einer Beziehung kann es trotz Liebe und Nähe leicht zu Missverständnissen und Konflikten kommen. 
        </p>
        <div class="block block--tablet-col">
            <card 
                title="Häufige Fragen"
                content="Sprachlosigkeit und Unsicherheit erschweren es, alltägliche Probleme oder situationsbedingte Unklarheiten anzusprechen. Ein klärendes Gespräch wird zur unüberwindbaren Hürde. 
                    Gibt es in einer Partnerschaft unklare Themen, kann dies die Sexualität innerhalb der Beziehung negativ beeinflussen."
                class="card--unset-fixed-width"
            />
            <card
                title="Paar- und Sexualtherapie hilft Ihnen wenn Sie"
                content="<ul>
                    <li>als Paar die Kommunikation in Ihrer Beziehung stärken möchten.</li>
                    <li>die gemeinsame WIR-Ebene neu definieren möchten.</li>
                    <li>die gemeinsame Liebe wiederentdecken möchten.</li>
                    <li>Ihre Weiblichkeit /Männlichkeit spüren und stärken möchten.</li>
                    <li>sich als lustvollen Menschen entdecken möchten.</li>
                    <li>die gemeinsame gelebte Sexualität (wieder) finden möchten.</li>

                    </ul>"
                class="card--unset-fixed-width"
            />
        </div>
        <h3>Mein Angebot</h3>
        <p>Ich arbeite als Sexual- und Paartherapeutin für Einzelpersonen und Paare.</p>

        <p>
            Ich helfe Ihnen, Unausgesprochenes zu artikulieren.
            Gemeinsam stärken wir Ihre Persönlichkeit, so dass sie einen sicheren, 
            respektvollen, liebevollen und angstfreien Zugang zu sich selbst finden. 
            Auf der partnerschaftlichen Ebene fördern wir das gegenseitige Verständnis.
        </p>
        <p>
            Meine Vorgehensweise orientiert sich an der Persölichkeit meiner Klienten. 
            Im Gespräch gehen wir gemeinsam den Problemen auf den Grund und räumen
            Schritt für Schritt die Steine aus dem Weg. Körperübungen, die Sie zuhause ausprobieren, 
            können dabei Ihren Prozess unterstützen und Sie in Ihrer Entwicklung fördern und stärken.
        </p>
        <scroll-top />
    </div>
</template>
<script>
import Card from '../components/Card.vue';
import ScrollTop from '../components/ScrollTop.vue';
export default {
    name: 'SexualPaartherapie',
    components: { 
        Card,
        ScrollTop
    },
}
</script>