<template>
  <div id="app">
    <Header />
    <div class="main">
      <div class="main__background-shape" />
      <div class="main__inner">
        <router-view></router-view>
      </div>
    </div>
    <Footer />
    <Flyout />
    <cookie-banner />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Flyout from './components/Flyout.vue';
import CookieBanner from './components/CookieBanner.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Flyout,
    CookieBanner
  }
}
</script>

<style lang="css">
  #app {
    overflow-x: hidden;  
  }
  .main {
    background-color: var(--background);
    margin-bottom: 320px;
    padding: 100px 0 100px;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid var(--border-card);
    overflow-x: hidden;
  }
  /* rounded shape in the background */
  .main__background-shape {
    width: 120%;
    left: -10%;
    height: 770px;
    background-color: var(--background-accent);
    border-bottom-left-radius: 50% 25%;
    border-bottom-right-radius: 50% 25%;
    position: absolute;
    top: 0;
    z-index: 0;
  }
  .main__inner {
    padding: 0 5% 0;
    overflow-x: hidden;
    max-width: 900px;
    margin: auto;
    /* for background image */
    z-index: 1;
    position: relative;
  }
  /* breakpoints main content */
  @media (min-width: 500px) {
    .main__inner {
      padding: 0 10% 0;
    }
  }
  @media (max-width: 500px) {
    .main {
      margin-bottom: 450px;
    }
  }
  @media (max-width: 750px) {
    .main {
      padding: 60px 0 100px;
    }
  }
</style>
