import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import VueCookies from 'vue-cookies'
// https://mazipan.github.io/vue-ionicons/
import AllIosIcon from 'vue-ionicons/dist/ionicons-ios.js';
require('vue-ionicons/ionicons.css')

import "./assets/css/variables.css";

createApp(App)
    .use(router)
    .use(store)
    .use(AllIosIcon)
    .use(VueCookies, { expires: '30d'})
    .mount('#app');
