<template>
    <div class="praxis">
        <h1>Meine Praxis</h1>
        <div class="block block--tablet-col">
            <div>
                <p>
                    Seit 2013 bin ich als Therapeutin in einer Gemeinschaftspraxis tätig.<br />
                    Die Praxis liegt zentral an der Bahnhofstrasse 4 in Buchs. Das Gebäude, in dem sich die Praxis befindet, steht hinter dem Gebäude Bahnhofstrasse 2. Der Eingang befindet sich links. Angeschrieben bin ich unter “Praxisgemeinschaft Buchs”.
                    Meine Praxis finden Sie im 5. Stock.
                </p>
            </div>
            <div class="praxis__imgs">
                <img src="@/assets/images/praxis-1.jpg" class="img" />
                <img src="@/assets/images/praxis-2.jpg" class="img" />
            </div>
        </div>
        <scroll-top />
    </div>
</template>
<script>
import ScrollTop from '../components/ScrollTop.vue';
export default {
    name: 'Praxis',
    components: {
        ScrollTop
    }
}
</script>
<style lang="css" scoped>
    .praxis__imgs {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 50%;
    }
    .praxis__imgs img {
        width: initial;
    }
    @media (max-width: 750px) {
        .praxis__imgs {
            margin-left: -15%;
            width: 130%;
        }
        .praxis__imgs .img {
            border-radius: 0;
            width: 100%;
        }
    }
</style>