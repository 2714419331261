<template>
    <div v-show="$store.state.showCookieBanner" class="cookie-banner">
        Diese Website nutzt Cookies, um bestmögliche Funktionalität bieten zu können. 
        <router-link to="/impressum">Datenschutz</router-link>
        <div class="cookie-banner__btn-container">
            <button class="btn btn--secondary btn--hover-style" @click="handleConsent(false)">
                Ablehnen
            </button>
            <button class="btn btn--primary btn--hover-style" @click="handleConsent(true)">
                Zustimmen
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CookieBanner',
    mounted () {
        // check cookies and show cookie banner
        const consentCookieSet = this.$cookies.get('consentGiven');

        // Cookie Banner has been interacted with once in the past
        if (consentCookieSet === 'true' || consentCookieSet === 'false') {
            this.$store.commit('setShowCookieBanner', false);
        }
        this.$store.commit('setConsentGiven', consentCookieSet === 'true');
    },
    methods: {
        handleConsent (consentGiven) {
            this.$cookies.set('consentGiven', consentGiven);
            this.$store.commit('setShowCookieBanner', false);
            this.$store.commit('setConsentGiven', consentGiven);
        }
    }
}
</script>
<style lang="css" scoped>
    .cookie-banner {
        position: fixed;
        bottom: 5%;
        left: 0;
        margin: 0 5%;
        min-width: 200px;
        max-width: 300px;
        padding: 20px;
        border: 1px solid var(--border-card);
        border-radius: 20px;
        background-color: var(--background-card);
        z-index: 2;
    }
    .cookie-banner__btn-container {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
    }
</style>