<template>
    <div class="section-four section__scroll-margin" id="praxis">
        <div class="block block--tablet-rev-col">
            <div class="block__content">
                <h2>Meine Praxis</h2>
                <p>
                    Seit 2013 bin ich als Therapeutin in einer Gemeinschaftspraxis tätig.
                    Die Praxis liegt zentral an der Bahnhofstrasse 4 in Buchs. Das Gebäude, in dem sich die Praxis befindet, steht hinter dem Gebäude Bahnhofstrasse 2. Der Eingang befindet sich links. Angeschrieben bin ich unter “Praxisgemeinschaft Buchs”.
                </p>
                <router-link to="/praxis">
                    <button class="btn btn--secondary">Mehr zu meiner Praxis</button>
                </router-link>
            </div>
            <!-- Google Maps Embed -->
            <iframe 
                v-if="$store.state.consentGiven"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10850.010208384236!2d9.4778065!3d47.1675963!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xee6b08d23bfdfac8!2sPraxis%20Bettina%20Thaler!5e0!3m2!1sde!2sat!4v1671874879014!5m2!1sde!2sat" 
                width="800" 
                height="400" 
                class="section-four__google-map" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
            <div v-else class="section-four__google-map section-four__google-map--no-consent">
                <h3>Inhalt ausgeblendet</h3>
                <p>An dieser Stelle befindet sich ein Inhalt von Google Maps. Um den Inhalt anzuzeigen, klicken Sie <a @click="showCookieBanner">hier</a> und stimmen Sie den Cookies zu.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeSectionFour',
    methods: {
        showCookieBanner () {
            this.$store.commit('setShowCookieBanner', true);
        }
    }
}
</script>
<style lang="css" scoped>
    .section-four__google-map {
        border: 0;
        border-radius: 20px;
        filter: grayscale(1);
    }
    .section-four__google-map--no-consent {
        width: 800px;
        background-color: var(--background-card);
        border: 1px solid var(--border-card);
        filter: unset;
        padding: 30px;
    }
    @media (max-width: 750px) {
        .section-four__google-map {
            margin-left: -12%;
            width: 124%;
            height: 300px;
            border-radius: 0;
        }
        .section-four__google-map--no-consent {
            margin-left: -12%;
            width: 113%;
        }
    }
    @media (max-width: 500px) {
        .section-four__google-map {
            margin-left: -5%;
            width: 110%;
        }
        .section-four__google-map--no-consent {
            margin-left: -5%;
            width: 95%;
        }
    }
</style>